<template>
  <div class="store-index">
    <van-search
      shape="round"
      placeholder="输入商品名"
      show-action
    >
      <template #left>
        <van-icon name="arrow-left" @click="$router.back(-1)" class="arrow" />
      </template>
      <template #action>
        <div class="search-action">
          <img
            width="24"
            src="../../../assets/img/takeOutFood/Chat.png"
            alt=""
          />
          <img
            width="24"
            src="../../../assets/img/takeOutFood/collection.png"
            alt=""
          />
          <img width="24" src="../../../assets/img/takeOutFood/share.png" />
        </div>
      </template>
    </van-search>
    <div style="height: 46px"></div>

    <!-- <div class="head">
      <img
        class="back"
        src="../../../assets/img/commoditySpike/back.png"
        alt="返回"
        @click="$router.back()"
      />

      <div class="title">
        <img src="../../../assets/img/takeOutFood/Search.png" alt="" />
        <span>输入商品名...</span>
      </div>

      <div class="left">
        <img src="../../../assets/img/takeOutFood/Chat.png" alt="" />
        <img
          class="chat"
          src="../../../assets/img/takeOutFood/collection.png"
          alt=""
        />
        <img
          @click="isOpenShare"
          class="share"
          src="../../../assets/img/takeOutFood/share.png"
        />
      </div>
    </div> -->

    
    <div class="box">
      <div class="store-info">
        <div class="store-desc">
          <div class="store-name">烤鱼先生·香辣烤鱼饭</div>
        </div>
        <img
          class="store-logo"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb319fdf96715dd957480db6f345c814597926d944b8a925136718817f83e9f01"
        />
        <div class="comment-box">
          <div class="store-star">
            <div class="star">
              <van-rate
                v-model="value"
                allow-half
                void-icon="star"
                readonly
                size="0.426667rem"
                color="#FF5200"
              />
              2.5分
            </div>
            <div class="sell-num">月售3242份</div>
          </div>

          <div class="coupon">
            <div class="coupon-item">
              <div class="coupon-desc"><div>8折</div></div>
              <div class="line"></div>
              <div class="coupon-get"><div>领</div></div>
            </div>
          </div>
          <div class="full-minus">
            <div class="full-minus-item">20减19</div>
            <div class="full-minus-item">20减19</div>
            <div class="full-minus-item">20减19</div>
            <div class="full-minus-item">20减19</div>
          </div>

<van-sticky :offset-top="46">
  
          <div class="store-content">
            <div class="title">
              <div :class="{ active: isActive }" @click="changeActive(true)">
                点菜
              </div>
              <div :class="{ active: !isActive }" @click="changeActive(false)">
                评价<span>769</span>
              </div>
            </div>
          </div>
</van-sticky>

          <div class="goods-box">
            <ul class="cate-list">
              <li
                class="cate-name"
                :class="{ 'cate-active': cateActive === 0 }"
              >
                折扣
              </li>
            </ul>
            <ul class="good-list">
              <div class="goods-cate">特价商品</div>
              <li class="good-item" v-for="item in 10" :key="item">
                <img
                  @click="goDetail"
                  class="good-img"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0b6b60e40e76daecab8d9ae83773e4a0a3e805000f0c983bc7dd6b1e0f63abcf"
                />
                <div class="good-content">
                  <div class="good-name">
                    【浓香】香辣无骨烤鱼饭+娃娃菜+金针菇+…
                  </div>
                  <div class="good-lable">
                    <div>香辣</div>
                    <div>香辣</div>
                  </div>
                  <div class="good-sell">
                    <div>月售234份</div>
                  </div>
                  <div class="good-price">
                    <div class="activeprice"><span>￥</span>35</div>
                    <div class="orginprice">￥45</div>
                  </div>
                  <div class="choose-specifications" @click="openChoose">
                    选规格
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <van-action-sheet
            v-model="show"
            title="购物车"
            :closeable="false"
            class="buycart-paul"
          >
            <div class="empty">
              <img src="../../../assets/img/takeOutFood/垃圾箱@2x.png" alt="" />
              清空购物车
            </div>
            <div class="content">
              <div class="cart-item">
                <img
                  class="cart-img"
                  referrerpolicy="no-referrer"
                  src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng0b6b60e40e76daecab8d9ae83773e4a0a3e805000f0c983bc7dd6b1e0f63abcf"
                />
                <div class="cart-info">
                  <div class="cart-name">
                    【两人份】酱香无骨烤鱼饭+娃娃菜+金针菇
                  </div>
                  <div class="desc">2人份</div>
                  <div class="good-price">
                    <div class="activeprice"><span>￥</span>35</div>

                    <div class="orginprice">￥45</div>
                  </div>
                </div>
                <div class="operation">
                  <img src="../../../assets/img/takeOutFood/jia.png" alt="" />
                  <input type="text" />
                  <img src="../../../assets/img/takeOutFood/Plus.png" alt="" />
                </div>
              </div>
            </div>
          </van-action-sheet>

          <div class="choose" v-show="isShowChoose" @click.self="closeChoose">
            <div class="choose-box">
              <div class="name">
                {{ "【人气】无骨烤鱼饭+娃娃菜+金针菇…".substr(0, 15) + "..." }}
              </div>
              <div class="choose-cate">
                <div class="title">分量</div>
                <div class="choose-item">
                  <div class="choose-active">1人份</div>
                  <div>1人份</div>
                  <div>1人份</div>
                </div>
              </div>
              <div class="choose-info">已选规格: <span></span></div>
              <div class="add">
                <p>总计</p>
                <div>￥<span>37.99</span></div>
              </div>
              <div class="addcart">+ 加入购物车</div>
            </div>
            <div class="close-choose" @click.self="closeChoose">
              <img
                @click="closeChoose"
                src="../../../assets/img/takeOutFood/close.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="buycart">
        <van-badge :content="1" max="99">
          <img
            @click="showCart"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng92b1d0cde994285448476988d8543a7f1ad6b40f0b37f3bebfe39a130545f491"
            alt=""
          />
        </van-badge>
        
        <span>购物车</span>
      </div>
      <div class="footer-box">
        <div class="info-total">
          <div class="buy-moeny">
            ￥12345
            <div class="total-price">￥12.1</div>
          </div>
          <div class="estimate">另预估配送费￥4.5</div>
        </div>
        <div class="settlement" @click="Submit()">去结算</div>
      </div>
    </div>

    <van-share-sheet
      z-index="6000"
      v-model="showShare"
      :options="options"
      title="立即分享给好友"
      description="描述信息"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showShare: false,
      options: [
        { name: "微信", icon: "wechat" },
        { name: "微博", icon: "weibo" },
        { name: "复制链接", icon: "link", description: "描述信息" },
        { name: "分享海报", icon: "poster" },
        { name: "二维码", icon: "qrcode" },
      ],

      value: 2.5,
      isActive: true,
      cateActive: 0,
      title: [1, 2, 3, 4, 5, 6, 7, 8],
      show: false,

      isShowChoose: false,
    };
  },
  methods: {
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      console.log(111);
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
    isOpenShare() {
      this.showShare = false;
    },
    changeActive(state) {
      this.isActive = state;
    },
    showCart() {
      this.show = true;
    },
    Submit() {
      // 提交订单
    },
    monitor(index) {
      //点击商品分类
      let titles = document.querySelectorAll(".cate-name");
      this.cateActive = index;
      window.scrollTo(0, titles[index].offsetTop);
    },
    //跳转到详情
    goDetail() {
      this.$router.push("/productDetails");
    },
    //关闭商品规格选择
    closeChoose() {
      this.isShowChoose = false;
    },
    //打开商品规格选择
    openChoose() {
      this.isShowChoose = true;
    },
  },
  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);

    window.addEventListener("scroll", () => {
      let getTitles = document.querySelectorAll(".goods-cate");
      getTitles.forEach((item, index) => {
        if (item.offsetTop - window.scrollY < 200) {
          this.cateActive = index;
        }
      });
    });
    window.addEventListener("scroll", () => {
      let getTitles = document.querySelectorAll(".goods-cate");
      getTitles.forEach((item, index) => {
        if (item.offsetTop - window.scrollY < 200) {
          this.cateActive = index;
        }
      });
    });
  },
  beforeDestroy() {
    document.documentElement.style.fontSize = "16px";
  },
};
</script>

<style lang="less" scoped>
.van-search {
  padding: 6px 12px;
  margin-bottom: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.van-search__action:active {
  background-color: #fff;
}
.arrow {
  padding: 0 16px 0 4px;
  font-size: 22px;
}
.search-action {
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

/deep/ .van-sticky--fixed {
  padding: 0 0.426667rem;
}

.store-index {
  background-color: white;
}
.head {
  padding: 0.533333rem 0.426667rem 0.266667rem 0.266667rem;
  background-color: white;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;

  align-items: center;
}
.head .back {
  width: 0.666667rem;
  height: 0.64rem;
  text-align: left;
}
.head .title {
  height: 0.666667rem;
  border-radius: 0.266667rem;
  background-color: #ccc;
  padding-left: 0.266667rem;
  margin-left: 0.106667rem;
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 0.373rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.head .title img {
  width: 0.48rem;
  height: 0.48rem;

  margin-right: 0.266667rem;
}
.head .left {
  display: flex;
  align-items: center;
}
.left img {
  width: 0.64rem;
  height: 0.64rem;
  margin-left: 0.266667rem;
}

.van-share-sheet {
  z-index: 6000 !important;
}

.box::-webkit-scrollbar {
  display: none;
}
.store-logo {
  float: right;
  height: 1.92rem;
  width: 1.92rem;
}
.box {
  flex: 1;
  overflow: auto;
  padding: 0 0.426667rem 0;
  box-sizing: border-box;
  height: 82vh;
}
.store-name {
  padding-top: 0.266667rem;
  height: 0.666rem;
  font-size: 0.48rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.666rem;
}
.store-star {
  color: #ff5200ff;
  font-size: 0.32rem;
  margin-bottom: 0.346667rem;
  display: flex;

  align-items: center;
  margin-top: 0.053333rem;
  margin-bottom: 0.266667rem;
}
.star {
  display: flex;
  align-items: center;
}
.star div:nth-child(1) {
  margin-right: 0.266667rem;
}
.sell-num {
  color: #ccc;
  margin-left: 0.266667rem;
  margin-left: 0.533rem;
}
.coupon {
  display: flex;
  align-items: center;
  margin-bottom: 0.133333rem;
}
.coupon-item {
  display: flex;
  align-items: center;
  height: 0.586667rem;
  width: 1.92rem;
  color: white;
  font-size: 0.32rem;
  font-weight: 600;
  box-sizing: border-box;
  margin-right: 0.133333rem;
}
.coupon-desc {
  height: 100%;
  width: 60%;
  border-right: 5px dashed #ff5200ff;
  border-radius: 0 0.08rem 0.08rem 0;
  background-color: #ff5200ff;
  text-align: center;
}
.coupon-desc div {
  line-height: 0.586667rem;
  height: 100%;
  width: 100%;
  font-family: PingFangSC-Semibold, PingFang SC;
  transform: scale(0.91);
  margin: auto;
}
.coupon-get {
  flex: 1;
  height: 100%;
  background-color: #ff5200ff;
  border-radius: 0.08rem 0 0 0.08rem;
}
.coupon-get div {
  font-family: PingFangSC-Semibold, PingFang SC;
  transform: scale(0.91);
  margin: auto;
  text-align: center;
  line-height: 0.586667rem;
}
.line {
  height: 0.266667rem;
  width: 0;
  border-left: 1px dotted #ff5200ff;
}
.full-minus {
  width: 4.826667rem;
  height: 0.4rem;
  border: 1px solid #ff5200ff;
  border-radius: 0.053333rem;
  display: flex;
  overflow: hidden;
  align-items: center;

  font-size: 0.32rem;
}
.full-minus .full-minus-item:last-of-type {
  border-right: none;
}
.full-minus-item {
  transform: scale(0.83);
  color: #ff5200ff;
  flex: 1;

  padding-right: 0.16rem;
  border-right: 1px solid #ff5200ff;
  text-align: center;
  line-height: 100%;
  width: 25%;
  white-space: nowrap;
}
.store-content.title {
  font-size: 0.426667rem;
  height: 1.093333rem;
  display: flex;
  align-items: center;
}
.store-content .title div {
  display: inline-block;
  margin-right: 1.173333rem;
  height: 1.093333rem;
  line-height: 1.093333rem;
  color: #999999;
  font-size: 0.426667rem;
}
.store-content .title div span {
  display: inline-block;
  font-size: 0.32rem;
  transform: scale(0.83);
}
.active {
  font-weight: 600 !important;
  border-bottom: 1px solid #ff5200ff;
  color: black !important ;
  font-size: 0.426667rem;
  font-family: PingFangSC-Semibold, PingFang SC;
}
.goods-box {
  padding-bottom: 0.4rem;
  height: 100%;
  display: flex;
}
.cate-list {
  width: 1.92rem;
  height: 100%;
  overflow: auto;
  color: #ccc;
}
.good-content {
  position: relative;
}
.cate-name {
  width: 100%;
  padding: 0.4rem 0;
}
.good-list {
  height: 100%;
  flex: 1;
}
.cate-active {
  font-weight: 600;
  color: black;
}
.goods-cate {
  padding: 0.426rem 0;
}
.van-tab {
  display: flex;
  flex-direction: column !important;
}
.good-item {
  display: flex;
  height: 2.453333rem;
}
.good-img {
  width: 2.453333rem;
  height: 2.453333rem;
  border-radius: 0.133333rem;
  margin-right: 0.4rem;
}
.good-name {
  font-size: 0.426667rem;
  font-weight: 600;
  height: 1.173333rem;
}
.good-lable {
  display: flex;
  height: 0.373333rem;
  margin-bottom: 0.106667rem;
}
.good-lable div {
  display: inline-block;
  padding: 0 0.133333rem;
  background: #d8d8d8;
  color: #333;
  margin-right: 0.133333rem;
  font-size: 0.32rem;
  transform: scale(0.8);
}
.good-sell {
  height: 0.32rem;
  position: relative;
  margin-bottom: 0.106667rem;
}
.good-sell div {
  position: absolute;
  font-size: 0.32rem;
  left: 0;
  top: 0;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  transform: scale(0.8);
}
.good-price {
  display: flex;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #a80000;
  vertical-align: bottom;
  align-items: flex-end;
}
.good-price span {
  font-size: 0.32rem;
}
.good-price .orginprice {
  color: #999;
  text-decoration: line-through;
  margin-left: 0.533333rem;
  font-size: 0.32rem;
}
.choose-specifications {
  position: absolute;
  background: #ff5200;
  text-align: center;
  right: 0.133333rem;
  bottom: 0.133333rem;
  height: 0.453333rem;
  font-size: 0.32rem;
  padding: 0 0.066667rem;
  line-height: 0.453333rem;
  color: white;
}
.footer {
  height: 1.6rem;
  background-color: white;
  color: white;
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  padding: 0 0.426667rem 0 0.24rem;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 100000000;
}
.buycart {
  color: black;
  display: flex;
  flex-direction: column;
  width: 1.146667rem;
  height: 0.906667rem;
  align-items: center;
}
.buycart img {
  width: 0.64rem;
  height: 0.64rem;
}
.buycart span {
  display: inline-block;
  font-size: 0.32rem;
  transform: scale(0.83);
}
.footer-box {
  background-color: black;
  width: 8.08rem;
  height: 0.96rem;
  display: flex;
  border-radius: 0.266667rem;
}
.info-total {
  height: 0.96rem;
  padding-left: 0.8rem;
  position: relative;
  flex: 1;
}
.buy-moeny {
  display: flex;
  font-size: 0.373333rem;
  height: 50%;
}
.total-price {
  font-size: 0.32rem;
  transform: translate(0.83);
  line-height: 0.586rem;
  color: #999;
}
.estimate {
  font-size: 0.32rem;
  transform: scale(0.83);
  position: absolute;
  left: 0.666rem;
  white-space: nowrap;
}
.settlement {
  width: 1.92rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/img/takeOutFood/btnbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 0.373333rem;
}

.content {
  position: relative;
  padding: 0.426rem 0.426rem 2.266rem;
  height: 100%;
}

.buycart-paul {
  max-height: 75%;
}

.empty {
  position: absolute;
  right: 0.533333rem;
  top: 0.4rem;
  display: flex;
  align-items: center;
  color: #999999ff;
}
.empty img {
  width: 0.506667rem;
  height: 0.506667rem;
  margin-right: 0.133333rem;
}
.cart-item {
  height: 2.48rem;
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 0.533333rem;
}
.cart-img {
  width: 2.48rem;
  height: 2.48rem;
  margin-right: 0.293333rem;
}
.cart-name {
  font-size: 0.346667rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  margin-bottom: 0.133333rem;
}
.cart-item .cart-info .good-price {
  font-size: 0.32rem;
}
.cart-item .cart-info span {
  transform: scale(0.75);
}
.cart-item .good-price {
  display: flex;
  align-items: center;
  align-items: flex-end;
}
.cart-item .orginprice {
  transform: scale(0.75);
}
.cart-item .cart-info .activeprice {
  font-size: 0.426667rem;
}
.operation {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  bottom: 0;
}
.operation img {
  width: 0.506667rem;
  height: 0.506667rem;
}
.operation input {
  width: 0.533333rem;
  text-align: center;
}
.choose {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 4rem;
  box-sizing: border-box;
}
.choose-box {
  width: 8.986667rem;
  height: 9.066667rem;
  background-color: white;
  border-radius: 0.093333rem;
  padding: 0.533333rem 0.4rem;
  box-sizing: border-box;
  margin: auto;
  position: relative;
}
.choose .name {
  font-size: 0.453333rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.64rem;
  margin-bottom: 0.293333rem;
}
.choose-item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  justify-content: space-between;
  align-items: center;
}
.choose-item .title {
  margin-bottom: 0.186667rem;
  font-size: 0.32rem;
}
.choose-item div {
  text-align: center;
  line-height: 0.826667rem;
  width: 2.533333rem;
  height: 0.826667rem;
  border: 0.001333rem solid #999999;
  border-radius: 0.625rem;
  font-size: 0.32rem;
  margin-bottom: 0.666667rem;
}
.choose-info {
  position: absolute;
  left: 0.4rem;
  bottom: 1.68rem;
  color: #999999ff;
}
.add {
  display: flex;
  position: absolute;
  bottom: 0.48rem;
  align-items: center;
}
.add p {
  margin-top: 0.133333rem;
  margin-right: 0.133333rem;
}
.add span {
  font-size: 0.64rem;
  font-weight: 600;
}
.addcart {
  position: absolute;
  right: 0.4rem;
  bottom: 0.586667rem;
  width: 2.693333rem;
  height: 0.746667rem;
  background: linear-gradient(135deg, #ff7200 0%, #ff3c00 100%);
  border-radius: 0.16rem;
  color: white;
  font-size: 0.32rem;
  line-height: 0.746667rem;
  font-family: PingFangSC-Regular, PingFang SC;
  text-align: center;
}
.close-choose {
  text-align: center;
}
.close-choose img {
  margin-top: 0.586667rem;
  width: 1.04rem;
  height: 1.04rem;
}
.choose .choose-active {
  background-color: #ffe5c8ff;
  border: 1px solid #ffe5c8ff;
  color: #ff5200ff;
  font-weight: 600;
}
</style>